<template>
  <v-container class="profile-summary-container pa-0">
    <h1 class="primary--text font-weight-bold">
      {{ $t("profile.header.personalData") }}
    </h1>
    <v-row no-gutters>
      <v-col cols="12" class="px-0 pb-3">
        <div class="d-flex justify-space-between">
          <h4 class="primary--text text-uppercase">
            {{ $t("profile.isCardOwner") }}
          </h4>
          <div class="mt-4">
            <router-link class="font-weight-bold" to="/profile/profile-update"
              ><span class="mr-2 text-decoration-underline">{{
                $t("profile.edit.data")
              }}</span></router-link
            >
          </div>
        </div>
        <v-card
          tile
          elevation="0"
          class="primary--text secondary lighten-2 rounded-sm"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="4" md="5" class="px-0 pb-3">
                <div class="field-wrapper">
                  <div class="f-label">{{ $t("profile.firstName") }}</div>
                  <div class="f-value">{{ user.firstName }}</div>
                </div>
              </v-col>
              <v-col cols="4" md="3" class="px-0 pb-3">
                <div class="field-wrapper">
                  <div class="f-label">{{ $t("profile.lastName") }}</div>
                  <div class="f-value">{{ user.lastName }}</div>
                </div>
              </v-col>
              <v-col cols="4" md="4" class="px-0 pb-3">
                <div class="field-wrapper">
                  <div class="f-label">{{ $t("profile.birthDate") }}</div>
                  <div class="f-value">
                    {{ $dayjs(user.person.birthDate).format("DD/MM/YYYY") }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="5" class="px-0 pb-3 pb-md-0">
                <div v-if="user.email" class="field-wrapper">
                  <div class="f-label">{{ $t("profile.email.email") }}</div>
                  <div class="f-value">{{ user.email }}</div>
                </div>
              </v-col>
              <v-col cols="4" md="3" class="px-0">
                <div
                  v-if="user.person && user.person.gender"
                  class="field-wrapper"
                >
                  <div class="f-label">
                    {{ $t("profile.gender") }}
                  </div>
                  <div class="f-value">{{ user.person.gender }}</div>
                </div>
              </v-col>
              <v-col cols="8" md="4" class="px-0">
                <div v-if="user.phone" class="field-wrapper">
                  <div class="f-label">
                    {{ $t("profile.contacts.mobilePhone") }}
                  </div>
                  <div class="f-value">{{ user.phone }}</div>
                </div>
                <div v-if="!user.email" class="error--text font-weight-bold">
                  {{ $t("profile.email.noEmail") }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-0 pb-3">
        <div class="d-flex justify-space-between">
          <h4 class="primary--text text-uppercase">
            {{ $t("profile.yourPassword") }}
          </h4>
          <div class="mt-4">
            <router-link
              class="font-weight-bold mt-4 text-decoration-underline"
              to="/profile/changepassword"
              ><span class="mr-2">{{
                $t("profile.edit.data")
              }}</span></router-link
            >
          </div>
        </div>
        <v-card
          tile
          elevation="0"
          class="primary--text secondary lighten-2 rounded-sm"
        >
          <v-card-text>
            <div class="field-wrapper">
              <div class="f-label">
                {{ $t("profile.password") }}
              </div>
              <div class="f-value">****************</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="px-0 pb-3">
        <div class="d-flex justify-space-between">
          <h4 class="primary--text text-uppercase">
            {{ $t("Card.title") }}
          </h4>
          <div class="mt-4">
            <router-link
              class="font-weight-bold mt-4 text-decoration-underline"
              to="/profile/tigros-card"
              ><span class="mr-2">{{
                $t("profile.viewCard")
              }}</span></router-link
            >
          </div>
        </div>
        <v-card
          tile
          elevation="0"
          class="primary--text secondary lighten-2 rounded-sm"
        >
          <v-card-text class="d-flex justify-space-between">
            <v-row no-gutters>
              <v-col
                cols="6"
                md="4"
                class="field-wrapper"
                v-if="user.fidelityCard"
              >
                <div class="f-label">
                  {{ $t("profile.cardNumber") }}
                </div>
                <div class="f-value">{{ user.fidelityCard }}</div>
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="field-wrapper"
                v-if="user.fidelityCard"
              >
                <div class="f-label">
                  {{ $t("profile.cartStatus") }}
                </div>
                <div class="f-value">Attivo</div>
              </v-col>
              <v-col cols="12" md="7" v-else>
                <v-btn color="secondary" depressed @click="regenerateCard">
                  Richiedi una Tigros Card
                </v-btn>
              </v-col>
              <v-col cols="0" md="5" class="d-none d-md-flex justify-end pr-8">
                <img
                  height="45px"
                  src="/img_layout/dashboard/tigros-card.svg"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="px-0 pb-3">
        <div class="d-flex justify-space-between">
          <h4 class="primary--text text-uppercase">
            {{ $t("profile.menu.addresses") }}
          </h4>
          <div class="mt-4">
            <router-link
              class="font-weight-bold mt-4 text-decoration-underline"
              to="/profile/addresses/manage"
              ><span class="mr-2">{{
                $t("profile.addresses.addAddress")
              }}</span></router-link
            >
          </div>
        </div>
        <v-card
          tile
          elevation="0"
          class="primary--text secondary lighten-2 rounded-sm"
        >
          <v-card-text>
            <div
              class="field-wrapper"
              v-for="(address, index) in userAddresses.addresses"
              :key="address.addressId"
            >
              <v-row no-gutters>
                <v-col cols="12" sm="9" md="9" lg="10">
                  <div class="f-label">
                    {{ $t("profile.addresses.home") }} {{ index + 1 }}
                  </div>
                  <div class="f-value">
                    {{ address.address1 }}, {{ address.addressNumber }} -
                    {{ address.city }} {{ address.postalcode }} ({{
                      address.province
                    }}), {{ address.country.name }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="2" class="text-right">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="main-button mr-2"
                        :to="'/profile/addresses/manage/' + address.addressId"
                      >
                        <v-icon medium>$edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifica indirizzo</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        large
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="secondary-icon-button"
                        @click="removeAddress(address.addressId)"
                      >
                        <v-icon>$delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Elimina indirizzo</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="$platform_is_cordova && isBetaTester"
        class="mt-8 development-link"
      >
        <router-link class="d-block mb-6" :to="{ name: 'Melaregalo' }">
          test mela regalo
        </router-link>

        <a :href="developmentLink">Apri versione di test </a>
      </v-col>
      <v-col v-if="$platform_is_cordova" cols="12">
        <div class="section default--text last links mt-5 text-center">
          <i18n
            path="footer.version"
            tag="span"
            class="my-1 child pl-2 text-center"
          >
            {{ version }}
          </i18n>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.profile-summary-container {
  .v-card {
    background-color: #f4f4f4 !important;
  }
  .v-card__text {
    div {
      font-size: 14px;
      color: $primary !important;
    }
  }
  a {
    text-decoration: none;
  }
  .development-link {
    text-decoration: underline !important;
    font-weight: 500;
  }
  .field-wrapper {
    margin-bottom: 5px;
    .f-value {
      font-weight: bold;
    }
  }
}
</style>
<script>
import RegistrationService from "@/service/tigrosRegistrationService";
import DeliveryService from "~/service/deliveryService";
import { mapGetters } from "vuex";
export default {
  name: "ProfileSummary",
  data() {
    return {
      userAddresses: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isBetaTester: "cart/isBetaTester"
    }),
    formattedAddress() {
      return `${this.user.billingAddress.address1}, ${this.user.billingAddress.addressNumber} - ${this.user.billingAddress.city} ${this.user.billingAddress.postalcode} (${this.user.billingAddress.province}), ${this.user.billingAddress.country.name}`;
    },
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile2/develop/" + platform + "/index.html";
    },
    version() {
      return global.config.version;
    }
  },
  methods: {
    async regenerateCard() {
      await RegistrationService.regenerateCard();
    },
    async fetchAddresses() {
      this.userAddresses = await DeliveryService.getWarehousesList("home");
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
